import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import vueHeadful from "vue-headful";
import VueConfetti from "vue-confetti";
import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";

Vue.use(require("vue-moment"));
Vue.use(VueConfetti);

Vue.use(Buefy, {
  defaultIconPack: "fad",
});
Vue.component("vue-headful", vueHeadful);
Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyB5lIU3Ac4xJr32Hwz9XavQLRGnWk8Ts_k",
  authDomain: "hmd-recred.firebaseapp.com",
  projectId: "hmd-recred",
  storageBucket: "hmd-recred.appspot.com",
  messagingSenderId: "41848697141",
  appId: "1:41848697141:web:a4efc6eb04565500b4dc7c",
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

let app;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
