import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.sessionStorage,
});

const getDefaultState = () => {
  return {
    officeMeta: undefined,
    officeResults: undefined,
  };
};

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    officeMeta: (state) => state.officeMeta,
    officeResults: (state) => state.officeResults,
  },
  mutations: {
    setOfficeMeta(state, payload) {
      state.officeMeta = payload;
    },
    setOfficeResults(state, payload) {
      state.officeResults = payload;
    },
  },
  modules: {},
  plugins: [vuexLocalStorage.plugin],
});
