import Vue from "vue";
import Router from "vue-router";
import firebase from "@firebase/app";
import "@firebase/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/recred",
      name: "Recred",
      component: () => import("@/views/Recred.vue"),
    },
    {
      path: "*",
      redirect: "/login",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;
  if (requiresAuth && !currentUser) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
